<template>
  <Navigation isRegistration />
  <div class="password">
    <div class="password__column">
      <h1 class="password__column__header">
        {{ $t('set-password-title') }}
      </h1>
      <p class="password__column__text">{{ $t('set-password-text') }}</p>
      <el-form label-position="top" :model="$data" :rules="$options.rules">
        <el-form-item
          :label="$t('label.password')"
          prop="password"
          @change="validatePassword"
          :class="{ password__column__error: error.password }"
        >
          <el-input
            v-model="password"
            :placeholder="$t('label.password')"
            show-password
          />
          <p v-if="error.password" class="password__column__error__text">
            {{ $t('error.password') }}
          </p>
        </el-form-item>
        <vue-recaptcha
          :siteKey="recaptchaSiteKey"
          :hl="currentLanguage"
          @verify="recaptcha = true"
          @expire="recaptchaExpired"
          ref="vueRecaptcha"
        />
      </el-form>
      <p class="password__column__message" v-if="userSetPasswordError">
        {{ $t('error.password-set') }}
      </p>
      <Button
        class="button--right"
        :label="$t('button.set-password')"
        :disabled="isDisabled"
        @click="setPassword"
      ><IconArrowRight />
      </Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vueRecaptcha from 'vue3-recaptcha2'

import Button from '@/components/Button.vue'
import Navigation from '@/components/Navigation.vue'
import IconArrowRight from '@/components/icons/IconArrowRight'

export default {
  components: {
    vueRecaptcha,
    Button,
    Navigation,
    IconArrowRight,
  },
  rules: {
    password: [
      {
        required: true,
        trigger: 'blur',
      },
    ],
  },
  data() {
    return {
      password: '',
      recaptcha: false,
      error: {
        password: false,
      },
    }
  },
  mounted() {
    // reset store
    this.$store.commit('setUserSetPassword', false)
    this.$store.commit('setUserSetPasswordError', false)
  },
  computed: {
    ...mapGetters(['currentLanguage', 'userSetPassword', 'userSetPasswordError']),
    isDisabled() {
      return !this.password || this.error.password || !this.recaptcha
    },
    recaptchaSiteKey() {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY
    },
  },
  watch: {
    userSetPassword() {
      if (this.userSetPassword) this.$router.push({ path: '/' })
    },
    // reset error message when form is changed
    $data: {
      handler() {
        if (this.userSetPasswordError) this.$store.commit('setUserSetPasswordError', false)
      },
      deep: true
    }
  },
  methods: {
    validatePassword() {
      if (this.password) {
        !this.password.match(
          '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
        )
          ? (this.error.password = true)
          : (this.error.password = false)
      }
    },
    setPassword() {
      this.$store.dispatch('userSetPassword', {
        password: this.password,
        key: this.$route.query.key,
        login: this.$route.query.login,
      })
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset()
      this.recaptcha = false
    },
  },
}
</script>

<style lang="scss" scoped>
.password {
  @include flex-center-align;
  flex-direction: column;
  padding-top: 80px;

  &__column {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    max-width: 650px;
    width: 100%;

    @include mq($from: mobile) {
      padding: 80px 20px;
    }

    &__header {
      margin-bottom: 40px;

      &::after {
        content: '';
        background-color: $color-primary;
        display: block;
        margin-top: 7px;
        height: 4px;
        width: 80px;
      }
    }

    &__text {
      margin-bottom: 40px;
    }

    &__error {
      input {
        border-color: $color-error;
      }

      &__text {
        color: $color-error;
        font-size: 14px;
        line-height: 14px;
        padding-top: 4px;
      }
    }

    &__message {
      color: $color-error;
      margin-top: 20px;
    }

    .button--right {
      margin-top: 24px;
      align-self: flex-end;
    }
  }
}
</style>
